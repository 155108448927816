import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@/app/components/Theme";
import BannerButton from "@/app/components/BannerButton";
import { cn } from "@/utils/helper";
import Footer from "@/app/components/Footer";

const heroImages = [
  {
    text: "Dream big, work hard: Your success is within reach!",
    image: "/home/hero-1.jpg",
    color: "blue",
  },
  {
    text: "Fuel your body, ignite your passion: Nutrition is key!",
    image: "/home/hero-2.jpg",
    color: "green",
  },
  {
    text: "If you put it on the calendar, it will happen!",
    image: "/home/hero-3.jpg",
    color: "yellow",
  },
  {
    text: "Every step counts: Progress is made one day at a time!",
    image: "/home/hero-4.jpg",
    color: "red",
  },
  {
    text: "Believe in yourself: Your potential is limitless and bright!",
    image: "/home/hero-5.jpg",
    color: "purple",
  },
  {
    text: "Stay committed, stay strong: Your journey is worth it!",
    image: "/home/hero-1.jpg",
    color: "cyan",
  },
  {
    text: "Push your limits: Growth happens outside your comfort zone!",
    image: "/home/hero-2.jpg",
    color: "brown",
  },
  {
    text: "Consistency is key: Small efforts lead to big results!",
    image: "/home/hero-3.jpg",
    color: "orange",
  },
  {
    text: "Embrace the challenge: Every obstacle is an opportunity!",
    image: "/home/hero-4.jpg",
    color: "red",
  },
  {
    text: "Your journey is unique: Celebrate every milestone you achieve!",
    image: "/home/hero-5.jpg",
    color: "purple",
  },
];

const features = [
  {
    title: "Helping you focus on the present moment.",
    description:
      "Concentrate on structuring your workouts for the upcoming week and assess your actual activities against your planned schedule.",
    imageLight: "/home/feature-2.jpg",
    imageDark: "/home/feature-2-dark.jpg",
  },
  {
    title: "Inquire with your virtual coach about anything.",
    description:
      "Your virtual coach is always available to assist you. You can ask any questions you may have. While they specialize in creating workout plans, they can also adjust current plans, analyze past workouts, and provide nutritional guidance.",
    imageLight: "/home/feature-3.gif",
    imageDark: "/home/feature-3-dark.gif",
  },
  {
    title: "Tailor your plan to your needs.",
    description:
      "You have complete control over your daily workouts. Your coach will be adaptable to any changes you make to the plan and will adjust the next day's workout accordingly. Feel free to modify the plan, add notes, or even introduce new workouts.",
    imageLight: "/home/feature-1.gif",
    imageDark: "/home/feature-1-dark.gif",
  },
];

const HomePublic = () => {
  const { isDarkMode } = useTheme();

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 300,
    pauseOnHover: true,
    cssEase: "ease-in-out",
  };

  return (
    <article className="w-full h-full mb-4 text-gray-800 overflow-auto scroll">
      <section className="w-full h-72">
        <Slider {...sliderSettings}>
          {heroImages.map((image, index) => (
            <div className="w-full h-72 relative shadow-lg" key={index}>
              <Image
                src={image.image}
                alt={image.text}
                width={1000}
                height={600}
                className="w-full h-full absolute inset-0 object-cover opacity-90 bg-gray-200 dark:bg-gray-800"
              />
              <div className={cn(["relative z-10 px-4 w-full h-full"])}>
                <h2
                  className="h2 absolute transform -translate-y-1/2"
                  style={{
                    top: "50%",
                    left: "10%",
                    width: "50%",
                    height: "70%",
                  }}
                >
                  {image.text}
                </h2>
                <div
                  className="absolute transform -translate-x-1/2 -translate-y-1/2"
                  style={{ top: "50%", left: "75%" }}
                >
                  <BannerButton btnColor={image.color} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <section className="container mx-auto w-full max-w-7xl text-gray-900 dark:text-gray-300">
        {features.map((feature, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-2 gap-16 my-8 items-start"
          >
            {index % 2 === 0 ? (
              <>
                <div className="w-full shadow-lg">
                  <Image
                    src={isDarkMode ? feature.imageDark : feature.imageLight}
                    alt={feature.title}
                    width={1000}
                    height={1000}
                  />
                </div>
                <div className="w-full">
                  <h2 className="h2">{feature.title}</h2>
                  <p className="text-lg">{feature.description}</p>
                </div>
              </>
            ) : (
              <>
                <div className="w-full">
                  <h3 className="h2">{feature.title}</h3>
                  <p className="text-lg">{feature.description}</p>
                </div>
                <div className="w-full shadow-lg">
                  {isDarkMode && (
                    <Image
                      src={feature.imageDark}
                      alt={feature.title}
                      width={1000}
                      height={1000}
                    />
                  )}
                  {!isDarkMode && (
                    <Image
                      src={feature.imageLight}
                      alt={feature.title}
                      width={1000}
                      height={1000}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </section>
      <Footer />
    </article>
  );
};

export default HomePublic;
