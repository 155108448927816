import { FaHeart } from "react-icons/fa";
import React from "react";
import { cn } from "@/utils/helper";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";

type BannerButtonProps = {
  btnColor?: string;
};

const BannerButton = ({ btnColor = "yellow" }: BannerButtonProps) => {
  const { isSignedIn } = useAuth();
  const FEATURE_GRAND_OPENING =
    typeof window !== "undefined" &&
    process.env.NEXT_PUBLIC_FEATURE_GRAND_OPENING === "true";

  return (
    <button
      className={cn(
        "animate-pulse btn btn-banner flex items-center gap-4",
        `btn-banner-${btnColor}`
      )}
      onClick={(e) => {
        e.preventDefault();
        if (FEATURE_GRAND_OPENING && !isSignedIn) {
          window.location.href = "/user/sign-in";
        } else {
          toast.info("Coming Soon!");
        }
      }}
    >
      <FaHeart className="w-6 h-6" />
      {FEATURE_GRAND_OPENING ? "Get Started" : "Coming Soon"}
    </button>
  );
};

export default BannerButton;
