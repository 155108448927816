import React from "react";

const Footer: React.FC = () => {
  const links = [
    {
      title: "Sign In",
      description: "Sign in to your account.",
      url: "/user/sign-in",
    },
    {
      title: "Privacy Policy",
      description:
        "Review our privacy policy to understand how we manage your data.",
      url: "/customer/privacy",
    },
    {
      title: "Customer Support",
      description: "Receive assistance with your account or any issues.",
      url: "/customer/support",
    },
    {
      title: "Contact Us",
      description: "Reach out to us with any questions or feedback.",
      url: "/customer/contact",
    },
    {
      title: "Sign Up",
      description: "Create an account to get started.",
      url: "/user/sign-up",
    },
    {
      title: "Terms of Service",
      description:
        "Read our terms of service to understand how we manage your data.",
      url: "/customer/terms",
    },
  ];

  return (
    <footer className="bg-gray-400 dark:bg-gray-700 text-white p-4">
      <div className="container mx-auto flex items-end justify-between gap-16">
        <div className="flex-1 grid grid-cols-1 sm:grid-cols-4">
          {links.map((link, index) => (
            <div key={index} className="p-2">
              <a
                href={link.url}
                className="hover:underline"
                title={link.description}
              >
                {link.title}
              </a>
            </div>
          ))}
        </div>
        <div className="text-center">
          &copy; {new Date().getFullYear()} Naozz LLC. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
